<template>
  <v-container class="receipt-detail d-flex flex-column px-0">
    <CategoryTitle :category="category" />

    <ReceiptDetail
      v-if="receipt"
      :receipt="receipt"
      :bill="bill"
      :warranty="warranty"
      :paymentType="paymentType"
      :digital="digital"
    />

    <div class="mt-5">
      <v-btn
        v-if="digital"
        color="primary"
        outlined
        v-bind:to="{ name: 'DigitalReceipts' }"
      >
        <v-icon>$prev</v-icon>
        {{ $t("receipt.button.backToDigitalReceipts") }}
      </v-btn>
      <v-btn v-else color="primary" outlined v-bind:to="{ name: 'Receipts' }">
        <v-icon>$prev</v-icon>
        {{ $t("receipt.button.backToReceipts") }}
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import ReceiptDetail from "@/components/orders/ReceiptDetail.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import TigrosRegistrationService from "@/service/tigrosRegistrationService";

import categoryMixins from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapState } from "vuex";

export default {
  name: "Receipt",
  mixins: [categoryMixins, reload],
  components: {
    ReceiptDetail,
    CategoryTitle
  },
  data() {
    return {
      receipt: null
    };
  },
  props: {
    bill: { type: String, required: false },
    warranty: { type: String, required: false },
    paymentType: { type: String, required: false },
    digital: { type: Boolean, default: false }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    async reload(orderId) {
      this.receipt = await TigrosRegistrationService.getReceiptDetail(orderId);
    }
  },
  created() {
    this.reload(this.$route.params.receiptId);
  }
};
</script>
