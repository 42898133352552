<template>
  <v-card class="receipt-detail pa-3 text-left grey lighten-3" flat>
    <!-- <v-card-title> </v-card-title> -->
    <v-card-text class="font-weight-bold primary--text text-body-1">
      <div>{{ $t("receipt.header.number") }}#{{ receipt.id }}</div>
      <div v-if="receipt.rif && receipt.rif.date">
        {{ $t("receipt.header.date")
        }}{{ $dayjs(receipt.rif.date).format("DD/MM/YYYY") }}
      </div>
      <div>{{ $t("receipt.header.type") }}Punto vendita</div>
      <div>{{ $t("receipt.header.paymentType") }}{{ paymentType }}</div>

      <div v-if="bill == 'true'" class="mt-2">
        Questo scontrino contiene il pagamento di una bolletta
      </div>
      <div v-if="warranty == 'true'" class="mt-2">
        Questo scontrino contiene una garanzia
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="printReceipt" depressed class="primary">
        <v-icon>$print</v-icon>
        <span class="text-center small">Stampa</span>
      </v-btn>
    </v-card-actions>
    <v-card-text class="receipt-rows">
      <div class="receipt-header-container">
        <img
          width="300"
          class="receipt-header-img"
          src="/img_layout/reserved_area/receipt/receipt-header.png"
          alt="Immagine garanzia"
        />
      </div>
      <div class="receipt-row" v-for="(row, index) in receiptRows" :key="index">
        <!-- <img
          src="/img_layout/reserved_area/receipt/receipt.png"
          alt="Sfondo scontrino"
          class="receipt-background-img"
        /> -->
        <div class="receipt-row-text-container">
          <div class="receipt-row-text">{{ row.text }}</div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.receipt-detail {
  .receipt-rows {
    background-image: url("/img_layout/reserved_area/receipt/receipt.png");
    background-repeat: repeat-y;
    background-size: 100%;
    width: 314px;
  }
  .receipt-header-container {
    height: 101px;
    img {
      max-width: 100%;
    }
  }
  .receipt-row {
    // width: 300px;
    // position: relative;
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    // .receipt-row-text-container {
    //   width: 100%;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    // }

    .receipt-row-text {
      font-family: monospace;
      white-space: pre;
      // padding: 0 10px;
      // position: absolute;
      // top: 0px;
      // left: 0px;
      font-size: 11px;
    }

    .receipt-background-img {
      width: 300px;
    }
  }
}
</style>
<script>
export default {
  name: "ReceiptDetail",
  props: {
    receipt: { type: Object, required: true },
    bill: { type: String, required: false },
    warranty: { type: String, required: false },
    paymentType: { type: String, required: false },
    digital: { type: Boolean, default: false }
  },
  computed: {
    receiptRows() {
      if (this.receipt && this.receipt.rows) {
        return this.receipt.rows.filter(function(row) {
          return row.text.indexOf("@") < 0;
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    printReceipt() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    }
  }
};
</script>
